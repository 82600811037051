var caStaticEnvironmentConfig = {
    "pdfLicenseKey": "Zim Integrated Shipping Services Ltd(zim.com):OEM:My Zim::B+:AMS(20210331):CBCC5F93079A24F34B313BC9B243382F4E6F0F87C1F535F5001DA40A1450375ABA39F5C7",
    "umbraco": {
        "translates": "/translations",
        "settings": "/cagetsettings",
        "settingsBypassingOSB": "/getsettings",
        "sidebarBypassingOSB": "/sidebar",
        "sidebar": "/getsidebar"
    },
    "commonEndPoints": {
        "tracing": "/getblcontainerstracing_v1",
        "setMyLanguage": "/setmylanguage_v1",
        "getTimeZonePicklist": "/gettimezonepicklist",
        "getMyUserProfile": "/getmyuserprofile",
        "setMyUserProfile": "/setmyuserprofile_v1",
        "statusPickList":"/getstatuspicklist"
    },
    "zimcomEndpoints": {
        "countries": "/umbraco/api/Countries/Get",
        "states": "/api/states"
    },
    "getmycontactEndPoint": {
        "contacts": "/getmycontact"
    },
    "arrivalNoticeEndPoint": {
        "arrivalNotices": "/getarrivalnotice_v1",
        "pickList": "/getblpicklist_v1",
        "documents": "/getarrivalnoticedocument_v1",
        "exportToXls": "/exptoxslarrnot*"
    },
    "deliveryOrderEndPoint": {
        "deliveryOrders": "/getdeliveryorder_v1",
        "pickList": "/getblpicklist_v1",
        "documents": "/getdeliveryorderdocument_v1",
        "exportToXls": "/exptoxsldelord"
    },
    "myShipmentsImportEndPoint": {
        "myShipments": "/getmyshipmentimport_v2",
        "pickList": "/getblpicklist_v1",
        "exportToXls": "/exptoxslmyspmp*"
    },
    "blCopysEndPoint": {
        "blCopys": "/getblcopy_v1",
        "pickList": "/getblpicklist_v1",
        "document": "/getblcopydocument_v1",
        "exportToXls": "/exptoxslcopybl_v1"
    },
    "bookingConfirmation": {
        "bookingConfirmations": "/getbookingconfirmation_v1",
        "pickList": "/getblpicklist_v1",
        "documents": "/getbookingconfirmationdocument_v1",
        "exportToXls": "/exptoxslbookingconfirmation_v1"
    },
    "myShipmentsExportEndPoint": {
        "myShipments": "/getmyshipmentexport_v2",
        "pickList": "/getblpicklist_v1",
        "exportToXls": "/exptoxslmySpxp"
    },
    "seawayBillEndPoint": {
        "seawayBills": "/getseawaybill_v1",
        "pickList": "/getblpicklist_v1",
        "document": "/getseawaybilldocument_v1",
        "state": "/setstate_v1",
        "exportToXls": "/exptoxslseawaybill_v1"
    },
    "financeEndPoint": {
        "finances": "/getcopyinvoices_v1",
        "pickList": "/getinvoicepicklist_v1",
        "document": "/getinvoicedocument_v1",
        "exportToXls": "/exptoxslfrtinvoicecopy*"
    },
    "printEndPoint": {
        "prints": "/getblprint_v1",
        "pickList": "/getblpicklist_v1",
        "document": "/getbldocument_v1",
        "state": "/setstate_v1",
        "exportToXls": "/exporttoxslPrintBL_v1"
    },
    "dashboardEndPoint": {
        "exportData": "/getexpsummaryview_v1",
        "importData": "/getimpsummaryview_v1",
        "zimMonitor": "/zimonitordashboard_v1"
    },
    "monitorEndPoint": {
        "openTrips": "/getlistoftripsstatus_v1",
        "containerReport": "/gettripsreport_v1",
        "containerHistory": "/getcontainerhistory_v1"
    },
    "userManagementEndPoint": {
        "createContactAndAuthorization": "/createcontactandauth_v1",
        "updateContactAndAuthorization": "/updatecontactandauth_v1",
        "setContactAuth": "/setcontactsauth_v1",
        "getContact": "/getcontact_v1",
        "dsaExists": "/dsaexists_v1",
        "getDSACustomersAndCategories": "/getdsacustsandcategories_v1",
        "getDSACustomerPicklist": "/getdsacustomerpicklist_v1",
        "getDSAContactsPicklist":"/getdsacontactspicklist_v1",
        "deleteContactAndAuthorization": "/deletecontactandauth_v1",
        "getDSAContactsAndAuthorization": "/getdsacontactsandauths_v1",
        "exportToXls": "/adminexportcontacts"
    },
    "notificationSettings": {
        "setNotificationSubscription": "/setnotificationsubscribtion",
        "getSpecificCargoNotifications": "/getspecificcargonotificationsubscriptioncounts",
        "deleteSpecificCargoNotification": "/removespecificcargoregistration",
        "checkReferenceAuthorization": "/checkreferenceauthorization",
        "getCargoNotificationsSubscription": "/getcargonotificationssubscription",
        "getCargoSubscriptionsPickList": "/getcargospecificntfrefsubscriptionpicklist"
    },
    "notificationsEndPoint": {
        "getNewNotificationsCounterConsumer": "/getnewnotificationscounter",
        "getNotificationEventsConsumer": "/getnotificationevents",
        "resetNotificationsCounterConsumer": "/resetnotificationscounter",
        "getDailyReportsConsumer": "/getdailyreports",
        "getDailyReportDocumentConsumer": "/getdailyreportdocument",
        "getVesselEventBLsConsumer": "/getvesseleventbls"
    },
    "gdprEndPoint":{
        "newGdprConfirmation": "/newgdprconfirmation",
        "getPurpose": "/getpurpose",
        "checkConsent": "/checkconsent"
    },
    "banners":{
        "get": "/api/getbanners",
        "setViewed": "/api/setbannerview",
        "stopShow": "/api/setbannerstopshow",
        "approve": "/api/setbannerapproval"
    },
    "draftBLEndPoint": {
        "draftBLs": "/GetDraftBL",
        "pickList": "/getblpicklist_v1",
        "versions": "/GetDraftBLVersions",
        "checkVersion": "/GetDraftBLCurrentVersion",
        "approve": "/ApproveDraftBLByCustomer",
        "sendToApprove": "/SubmitDraftBLByCustomer",
        "getPDFName": "/GetDraftBLDocument"
    },
    "vgmEndpoint": {
        "consignmentDetails": "/GetConsignmentDetailsForVgm",
        "unitsDetails": "/GetUnitsDetails",
        "submit": "/SendSubmitVgmConsumer"
    },
    "news": {
        "all": "/api/local-news",
        "one": "/api/local-news-one"
    }
}
